<template>
  <div>
    <top-bar :title="'公告详情'" :left="true"></top-bar>
    <van-tabs v-model="activeName" color="#387FF5">
      <van-tab title="公告信息" name="1">
        <div class="info">
          <div class="infoBox">
            <div class="title">
              <p>{{detailData.title}}</p>
              <div>
                <span>{{detailData.createTime}}</span>
                <span style="float: right; color: #666">{{detailData.readNum || 0}} 人已读</span>
              </div>
            </div>
            <van-divider :hairline="false" />
            <div class="content" v-html="detailData.content"></div>
          </div>
        </div>

      </van-tab>
      <van-tab title="阅读情况" name="2">
        <div style="background: #fff;">
          <div class="filterBar" style="height: inherit;">
            <div class="search">
              <input v-model="searchValue" type="search" value="搜索" placeholder="请输入关键字搜索" class="search-content"
                @keyup.enter="onSearch">
              <img :src="require('@/assets/img/clear.png')" class="search-clear" @click="onCancel">
              <div class="search-btn" @click="onSearch">搜索</div>
            </div>
            <div class="items-button">
              <div class="btn" :class="isChecked ? '' : 'checked'" @click="readHandle(0)" style="margin-right: 20px">未读
              </div>
              <div class="btn" :class="isChecked ? 'checked' : ''" @click="readHandle(1)">已读</div>
            </div>
          </div>

          <div class="content">
            <div class="content-title" v-if="!isChecked">未读人数：<span class="count">{{totalCount}}人</span></div>
            <div class="content-title" v-else>已读人数：<span class="count">{{totalCount}}人</span></div>
            <van-list v-model="loading" :finished="finished" finished-text="没有更多了" @load="getReadList"
              v-if="dataList.length > 0" offset="10">
              <div class="content-item" v-for="item in dataList" :key="item.id">
                <div class="name">{{item.name}}</div>
                <div class="mobile">{{item.mobile}}</div>
              </div>
            </van-list>
            <van-image v-else :src="require('@/assets/img/empty.png')" style="margin-left:10%;width:80%;"></van-image>
          </div>
        </div>

      </van-tab>
    </van-tabs>
  </div>

</template>

<script>
import topBar from '@/components/topBar/topBar'

import { mapState, mapActions } from 'vuex'
export default {
  data() {
    return {
      id: 0,
      detailData: {},
      activeName: '1',
      searchValue: '',
      page: 0,
      limit: 10,
      totalCount: 0,
      isChecked: false,
      loading: false,
      finished: false,
      dataList: []
    }
  },
  computed: { ...mapState(['noticeId']) },
  components: {
    topBar
  },
  methods: {
    getDetail() {
      console.log(this.noticeId);
      this.$http({
        url: this.$http.adornUrl('/wxapp/commonApp/notice/info'),
        method: 'post',
        params: this.$http.adornParams({
          orgUserId: this.$globalData.userInfo.userId,
          id: this.noticeId
        })
      }).then(({ data }) => {
        if (data.code == 0) {
          this.detailData = data.commonAppNotice
          this.$toast.clear()
        } else {
          console.log(data);
          this.$toast.clear()
          this.$toast.fail(data.msg);
        }
      })
    },
    onCancel() {
      this.page = 0
      this.searchValue = ''
      this.finished = false
      this.dataList = []
      this.getReadList(this.type)
    },
    onSearch() {
      this.page = 0
      this.dataList = []
      this.$toast.loading({
        duration: 0,
        message: '加载中...',
        forbidClick: true,
      });
      this.getReadList()
    },
    getReadList() {
      this.page++
      this.$http({
        url: this.$http.adornUrl('/wxapp/commonApp/notice/user/list'),
        method: 'get',
        params: this.$http.adornParams({
          page: this.page,
          limit: this.limit,
          noticeId: this.noticeId,
          userRead: this.type || 0,
          searchValue: this.searchValue
        })
      }).then(({ data }) => {
        if (data && data.code === 0) {
          this.totalCount = data.page.totalCount
          if (this.dataList.length == data.page.totalCount) {
            this.finished = true
          }
          this.dataList = this.dataList.concat(data.page.list)
          this.loading = false;
        }
        this.$toast.clear()
      })
    },
    readHandle(type) {
      this.isChecked = !this.isChecked
      this.dataList = []
      this.page = 0
      this.type = type
      this.getReadList()
    },
  },
  created() {
    this.$toast.loading({
      duration: 0,
      message: '加载中...',
      forbidClick: true,
    });
    this.id = this.$route.query.id
    this.getDetail()
    this.getReadList()
  }
}
</script>
<style>
a {
  color: rgb(4, 50, 255);
}
</style>
<style lang="scss" scoped>
.info {
  .infoBox {
    border-radius: 8px;
    background-color: #fff;
    padding: 30px;

    .title {
      p {
        font-size: 36px;
        margin: 10px 0 20px;
      }

      span {
        font-size: 22px;
        color: #999;
      }
    }

    .content {
      font-size: 30px;
    }
  }
}

.filterBar {
  height: 128px;
}

.search {
  font-size: 24px;
  background-color: #fff;
  width: 100%;
  padding: 20px 30px 0;
  display: flex;
  justify-content: space-between;
  position: relative;

  .search-content {
    width: 550px;
    height: 60px;
    border-radius: 40px;
    border: 1px solid #E8E8E8;
    padding-left: 30px;
    position: relative;

    &::placeholder {
      color: #999999;
    }
  }

  .search-clear {
    width: 22px;
    height: 22px;
    position: absolute;
    right: 200px;
    top: 40px;
  }

  .search-btn {
    width: 122px;
    line-height: 60px;
    text-align: center;
    border-radius: 60px;
    color: #fff;
    background-color: #387FF5;
  }
}

.items-button {
  height: 104px;
  display: flex;
  padding: 0 30px;
  justify-content: space-between;
  align-items: center;

  .btn {
    width: 324px;
    height: 56px;
    background: #EEEEEE;
    border-radius: 8px;
    font-size: 24px;
    font-family: PingFangSC-Regular;
    font-weight: 400;
    line-height: 56px;
    text-align: center;
    color: #666666;
  }

  .checked {
    background: rgba(56, 127, 245, 0.12);
    border: 2px solid #387FF5;
    color: #387FF5;
  }
}

.content {
  padding: 0 30px;

  .content-title {
    width: 690px;
    height: 88px;
    background: rgba(255, 255, 255, 0);
    border-radius: 10px 10px 0px 0px;
    font-size: 24px;
    font-family: PingFang-Bold;
    font-weight: 500;
    color: #666666;
    line-height: 88px;

    .count {
      color: #333333;
    }
  }

  .content-item {
    height: 134px;
    border-bottom: 2px solid #EDEDED;
    ;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: left;
    padding: 24px 0;

    .name {
      font-size: 28px;
      font-family: PingFang-Bold;
      font-weight: 500;
      color: #333333;
      line-height: 20px;

      .postName {
        color: #666666;
        font-family: PingFangSC-Regular;
      }
    }

    .mobile {
      font-size: 24px;
      font-family: PingFang-Bold;
      font-weight: 500;
      color: #666666;
    }

    &:nth-last-child(1) {
      border: none;
    }
  }
}
</style>
